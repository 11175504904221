
  import Vue from "vue";
  import {UserActionTypes} from "@/store/modules/user/actions";
  import {MODAL_IDS} from "@/utils/modal";
  import {LANGS} from "@/utils/i18n";
  import PwaStepper from "@/components/common/PwaStepper.vue";
  import PwaStep from "@/components/common/PwaStep.vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import {ROUTES} from "@/router/routes";

  const STEP_IDS = {GAME_SELECTION: 1, USER_INPUT: 2};
  export default Vue.extend({
    name: "pwa-add-game",
    components: {
      PwaStepper,
      PwaStep,
      PwaIcon,
      PwaJsonEditor: () => import('@/components/PwaJsonEditor.vue'),
      PwaSlider: () => import("@/components/common/PwaSlider.vue"),
    },
    data() {
      return {
        gameSettings: null,
        userSettings: null,
        currentStep: STEP_IDS.GAME_SELECTION,
        games: [] as any,
        gamesToShow: [] as any,
        gameSelected: null,
        userGames: [] as any,
        title: '',
        gameId: null,
        gamesBlock: 0,
        STEP_IDS,
      }
    },
    props: {
      defaultGameAlias: {
        type: String,
        required: false,
        default: null,
      },
      defaultRelatedGameId: {
        type: Number,
        required: false,
        default: 0,
      },
      nextModal: {
        type: String,
        required: false,
        default: ''
      },
      currentPage: {
        type: Boolean,
        required: false,
        default: false
      },
    },
    computed: {
      validated(): boolean {
        return this.userSettings
          ? parseInt(this.userSettings.validated) === 1
          : false;
      },
      currentUserSettings(): any {
        return this.userSettings
          ? this.userSettings.settings
          : null;
      },
      currentGameSettings(): string {
        let settings = null;
        if (this.gameSettings) {
          settings = this.currentLang === LANGS.ES
            ? this.gameSettings.settings_es
            : this.gameSettings.settings;
        }

        return settings;
      },
      hasGameConfigured(): boolean {
        let isGameConfigured = false;
        if (this.userGames.length > 0 && this.gameSelected) {
          if (this.userGames.find((game: any) => this.gameSelected.game_id === game.game_id && game.validated === 1)) {
            isGameConfigured = true;
          }
        }
        return isGameConfigured;
      },
      stepper(): any {
        return this.$refs.stepper;
      },
      canContinue(): boolean {
        return this.gameSelected != null && !this.hasGameConfigured;
      },
      gamesPerBlock(): number {
        return this.isDesktop
          ? null
          : 5;
      },
    },
    mounted() {
      this.currentStep = STEP_IDS.GAME_SELECTION;
      this.gameSelected = null;
      this.getAddGamePage();
    },
    methods: {
      async getAddGamePage() {
        try {
          this.showLoader(true);
          const {data} = await this.$http.page.getAddGamePage();
          this.games = data.data.games;
          this.updateGamesToShow();
          this.userGames = data.data.user_games;

          if (this.defaultGameAlias || this.defaultRelatedGameId) {
            const value = this.defaultGameAlias ? this.defaultGameAlias : this.defaultRelatedGameId;
            const property = this.defaultGameAlias ? 'alias' : 'related_game_id';
            const gameIndex = this.games.findIndex((game: any) => game[property] == value);
            if (gameIndex >= 0) {
              this.gameSelected = this.games[gameIndex];
              this.gameId = this.gameSelected.game_id;
              this.title = this.$t('195', {name: this.gameSelected.name});
              this.currentStep = STEP_IDS.USER_INPUT;
              await this.loadGameData();
            }
          }
        } catch (e) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      showMoreGames(): void {
        this.gamesBlock = this.gamesBlock + 1;
        this.updateGamesToShow();
      },
      updateGamesToShow(): void {
        if (this.gamesPerBlock) {
          const current = this.gamesBlock * this.gamesPerBlock;
          this.gamesToShow = this.games.slice(0, current + this.gamesPerBlock);
        }
      },
      async loadGameData() {
        try {
          this.showLoader(true);
          const [gamesSettings, userGame] = await Promise.all([
            this.$http.game.getGamesSettings(this.gameId),
            this.$http.user.getUserGame(this.userId, this.gameId),
          ]);

          this.gameSettings = gamesSettings.data.data || null;
          const userSettings = userGame.data.data || [];
          this.userSettings = userSettings.length > 0
            ? userSettings[0]
            : null;

        } catch (e) {
          this.showToastError(this.$t('139'), this.$t('140'));
          this.onCancelClick();
        } finally {
          this.showLoader(false);
        }
      },
      onContinueClick() {
        if (this.currentStep === this.STEP_IDS.GAME_SELECTION) {
          this.loadGameData();
          this.currentStep = this.STEP_IDS.USER_INPUT;
        } else if (this.currentStep === this.STEP_IDS.USER_INPUT) {
          this.onSendClick();
        }
      },
      onSendClick() {
        const jsonEditor = this.$refs.jsonEditor as any;
        const valid = jsonEditor.validate();
        if (valid) {
          const values = jsonEditor.getValues();
          this.saveData(values);
        }
      },
      async saveData(settings: any) {
        const isNewGame = !this.userSettings;
        try {
          this.showLoader(true);
          const request = isNewGame ? this.$http.user.postUserGames : this.$http.user.patchUserGames;
          await request(this.userId, this.gameId, {settings: {settings}});
          this.showLoader(false);
          this.showToastSuccess(this.$t('333'), this.$t('196'));
          await this.$store.dispatch(UserActionTypes.UPDATE_USER_GAMES);
          if ((this.defaultGameAlias != this.gameSelected.alias || this.defaultRelatedGameId) && !this.currentPage) {
            await this.$router.push({name: ROUTES.arena.name, params: {lang: this.routeLang, gameAlias: this.gameSelected.alias}});
          }
          this.onCancelClick();
        } catch ({response}) {
          this.showLoader(false);
          const errorData = response?.data || {};
          const serverMessage = errorData?.message || errorData.errors || '';
          const errorMessage = serverMessage && response?.status !== 202 ? serverMessage : this.$t('140');
          this.showToastError(this.$t('139'), errorMessage);
        }
      },
      onCancelClick() {
        this.hideModalById(MODAL_IDS.ADD_GAME);
        if (this.nextModal) {
          this.showModalById(this.nextModal);
        }
      },
      setCurrentStep(step: number) {
        this.currentStep = step;
      },
      onGameSelect(game: any) {
        this.gameSelected = game;
        this.title = this.$t('195', {name: this.gameSelected.name});
        this.gameId = this.gameSelected.game_id;
      }
    },
    watch: {
      currentStep() {
        this.stepper.setStep(this.currentStep);
      }
    }
  })
